import React from 'react';
import PropTypes from 'prop-types';
import './product.less'

/* 定义参数类型 */
const propTypes = {
    title: PropTypes.string.isRequired,
    subTitle: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    img: PropTypes.string.isRequired,
    imgAlign: PropTypes.string.isRequired,
}

/* 轮播图片组件 */
function MyProduct(props) {
    let { title, subTitle, content, img, imgAlign } = props;

    return (
        <div className="my-product">
            <div className="product">
                {imgAlign === 'left' ? <div className="img"><img src={img} alt='' /></div> : null}
                <div className="text">
                    <div className="title">{title}</div>
                    <div className="sub-title">{subTitle}</div>
                    <div className="text-content" dangerouslySetInnerHTML={{ __html: content }} ></div>
                </div>
                {imgAlign === 'right' ? <div className="img"><img src={img} alt='' /></div> : null}


            </div>
        </div>
    );
}

MyProduct.propTypes = propTypes;

export default MyProduct;