import React, { Component } from 'react';
// import PropTypes from 'prop-types';
// import { connect } from 'react-redux';
// import { Row, Col } from 'antd';
// import { withRouter, } from 'react-router-dom';
import './use.less';
// import Header from '../../component/header/index';
// import Footer from '../../component/footer/index';
// import imgBanner from '../../common/images/banner.png';
// import Title from '../../component/title/index';

class Use extends Component {

    render() {
        const { title, smallTitle, content } = this.props;
        return <div className='use'>
            <div className="content ">
                <span className="title">{title}<small>{smallTitle}</small></span>
                <span className="sub-content">{content}</span>
            </div>
        </div>

    }

}

export default Use;