import axios from 'axios';
import qs from 'qs';
import { notification } from 'antd';
import * as cons from './const';
import { isEmptyObject } from './reg';
import { API_ADMIN } from './urls';

function checkStatus(response) {
  if (response.status >= cons.HTTP_ERROR_STATUS_OK && response.status < cons.HTTP_ERROR_STATUS_NOT_MODIFIED) {
    return response;
  }
  if (response.status === 500) {
    return response;
  }
  if (response.status !== 401) {
    const errortext = cons.codeMessage[response.status] || response.statusText;
    notification.error({
      message: `请求错误 ${response.status}: ${response.url}`,
      description: errortext,
    });
    const error = new Error(errortext);
    error.name = response.status;
    error.response = response;
    throw error;
  } else if (response.status === 401) {
    const errortext = cons.codeMessage[response.status] || response.statusText;
    const error = new Error(errortext);
    error.name = response.status;
    error.response = response;
    throw error;
  }
}

/**
 * Requests a URL, returning a promise.
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 * @return {object}           An object containing either "data" or "err"
 */
// @withRouter
async function request(options) {
  try {
    let reqHeaders = '',
      data = '';
    if (options.headers === 'json') {
      reqHeaders = { 'content-type': 'application/json;charset=UTF-8' };
      data = options.params;
    } else {
      reqHeaders = { 'content-type': 'application/x-www-form-urlencoded' };
      data = qs.stringify(options.params);
    }

    let response = await axios({
      url: options.url,
      method: options.method || 'POST',
      baseURL: options.baseURL || API_ADMIN,
      headers: reqHeaders, // { 'content-type': 'application/x-www-form-urlencoded' },
      data: data, //qs.stringify(options.params),
    });
    response = checkStatus(response);
    const { code } = response.data;
    if (isEmptyObject(response)) {
      window.location.href = '/exception/500';
    }
    if (code >= cons.HTTP_ERROR_STATUS_FILENOTFOUND && code < cons.HTTP_ERROR_STATUS_AUTH_ERROR) {
      // window.location.href = '/exception/404';
    }
    return response.data;
  } catch (error) {
    console.error('request', error);
    const status = error.response.status;
    if (status === cons.HTTP_ERROR_STATUS_FORBIDEN) {
      window.location.href = '/exception/403';
    }
    if (status <= cons.HTTP_ERROR_STATUS_GATEWAY_TIMEOUT && status >= cons.HTTP_ERROR_STATUS_INTERNALSERVERERROR) {
      console.log('服务器错误!');
      return error.response.data;
    }
    if (status >= cons.HTTP_ERROR_STATUS_FILENOTFOUND && status < cons.HTTP_ERROR_STATUS_AUTH_ERROR) {
      // window.location.href = '/exception/404';
    }
  }
}

export default request;
