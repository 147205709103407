import React, { Component } from 'react';
import imgBanner from '../../common/images/joinus/joinus-banner.png';
import jobBg from '../../common/images/joinus/job-bg.png';

import './style.less';
import { Button, Carousel, Icon, Slider } from '../../../node_modules/antd/lib/index';
import img1 from '../../common/images/joinus/1.png'
import img2 from '../../common/images/joinus/2.jpg'
import img3 from '../../common/images/joinus/3.jpeg'
import img4 from '../../common/images/joinus/4.jpeg'
import img5 from '../../common/images/joinus/5.jpeg'

import CarouselImage from '../../component/carousel/sub/images';
import Marquee from '../../component/react-marquee/index';
    const loopData =    [img1,img2,img3,img4,img5]
        const url = 'https://www.zhipin.com/gongsi/a99353633a6f53b803x92d-5Fg~~.html?ka=search_rcmd_company_a99353633a6f53b803x92d-5Fg~~_custompage'
// @withRouter
class Joinus extends Component {


    render() {
        const jobDic = [{ url: 'bef714d0307cade21HJ429m_GVtQ.html', title: '研发总监' },
        {title: '高级Java工程师' },
        { title: '前端开发工程师' },
        {title: '产品总监' },
        {  title: '交互设计师' },
        {title: '销售总监(全国)' },
        { title: '售后实施工程师(全国)' },
        { title: '电网销专员' },
        {  title: '客户成功专员' },]
        // const layoutService = { xs: 24, sm: 24, md: 24, lg: 12, xl: 12 };
        // const layout4 = { xs: 8, sm: 8, md: 8, lg: 8, xl: 8 };
        const layout6 = { xs: 24, sm: 24, md: 24, lg: 12, xl: 12 };
        return <div className='joinus-page'>
            <div className='banner'><img src={imgBanner} alt='菜小秘' /></div>
            <div class="job-container">
                <div class="part-title-container">
                    <div class="part-title"> 招聘岗位</div>
                    <div className='part-desc'>
                        <div>人才即企业的未来，企业的发展离不开优秀的人才。我们希望你：正直、坚持、用心、包容</div>
                        <div>和菜小秘一起为每一个农批人实现数字化转型努力</div>
                    </div>
                </div>
                <div class="job-card-container">
                    {jobDic.map(item => {
                        return (
                            <div class="job-card">
                                <div class="job-card-title">{item.title}</div>
                            </div>
                        )
                    })}
                </div>


                <div className='job-footer'>
                    <div className='bottom-button' onClick={() => window.open(url)}>查看岗位详情<Icon type="right" className="bottom-icon" /></div>
                    <div className='bottom-info'><span>简历投递邮箱：hr@caixm.cn</span> <span>联系电话：13297089103</span></div>
                </div>
                <img className={'job-bg'} src={jobBg} alt='菜小秘' />
            </div>
            <div class="team-container">
                <div class="part-title-container">
                    <div class="part-title"> 团队风采</div>
                </div>
                <div className="my-marquee-page">
                    <Marquee loopData={loopData} getMarquee={this.getLandscapeMarquee} >
                        {loopData.map((v, i) => {
                            return <div key={i} className="marquee-landscape-txt">
                                <div className="img"><img src={v} alt='' /></div>
                            </div>
                        }
                        )}
                    </Marquee>
                </div>
            </div>
        </div>

    }

}

export default Joinus;