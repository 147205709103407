import * as actionTypes from '../constant/download';
import * as req from '../request/download';

/**
 * 获得pos机的数据
 */
export function getCxmData() {
    return async dispatch => {
        const res = await req.getCxmData();
        if (res) {
            dispatch({ type: actionTypes.DOWNLOAD_CXM, payload: res })
        }
    }
}
/**
 * 获得pos机的数据
 */
export function getPosData() {
    return async dispatch => {
        const res = await req.getPosData();
        if (res) {
            dispatch({ type: actionTypes.DOWNLOAD_POS, payload: res })
        }
    }
}
/**
 * 获得手机收银机的数据
 */
export function getMobilePosData() {
    return async dispatch => {
        const res = await req.getMobilePosData();
        if (res) {
            dispatch({ type: actionTypes.DOWNLOAD_MOBILE_POS, payload: res })
        }
    }
}
/**
 * 获得手机档主端的数据
 */
export function getMobileStallData() {
    return async dispatch => {
        const res = await req.getMobileStallData();
        if (res) {
            dispatch({ type: actionTypes.DOWNLOAD_MOBILE_STALL, payload: res })
        }
    }
}

