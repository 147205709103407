import React, { Component } from 'react';
// import PropTypes from 'prop-types';
// import { connect } from 'react-redux';
// import { Row, Col } from 'antd';
// import { withRouter, } from 'react-router-dom';
import imgBanner from '../../common/images/download/download-banner.jpg';
import Title from '../../component/title/index';
import HomeDownload from '../home/index/download';
import './style.less';

// @withRouter
class Download extends Component {

    render() {
        // const layoutService = { xs: 24, sm: 24, md: 24, lg: 12, xl: 12 };
        // const layout4 = { xs: 8, sm: 8, md: 8, lg: 8, xl: 8 };
        const layout6 = { xs: 24, sm: 24, md: 24, lg: 12, xl: 12 };
        return <div className='download-page'>
            <div className='banner'><img src={imgBanner} alt='菜小秘' /></div>
            <div className="content2">
                <HomeDownload layout6={layout6} source="download-page" />
            </div>

        </div>

    }

}

export default Download;