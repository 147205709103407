import React, { Component } from 'react';
// import PropTypes from 'prop-types';
// import { connect } from 'react-redux';
// import { Row, Col } from 'antd';
// import { withRouter, } from 'react-router-dom';
import './service.less';
// import Header from '../../component/header/index';
// import Footer from '../../component/footer/index';
// import imgBanner from '../../common/images/banner.png';
// import Title from '../../component/title/index';

class Service extends Component {

    render() {
        const { img, imgHover, title, content } = this.props;
        return <div className='service'>
            <div className="content ">
                <img src={img} alt='' className="img" />
                <img src={imgHover} alt='' className="img-hover" />
                <span className="title">{title}</span>
                <span className="sub-content" dangerouslySetInnerHTML={{ __html: content }}></span>
            </div>
        </div>

    }

}

export default Service;