import React, { Component } from 'react';
// import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'

import './style.less';

@withRouter
class Help extends Component {

    render() {
        return <div className='help-index'>
            help
            <Link to='/'>返回到网站</Link>
        </div>
    }
}

export default Help;