import * as actionTypes from '../constant/phone';

//初始状态
const initState = {
    addPhone: {},
}
//reducer
export function phone(state = initState, action) {
    switch (action.type) {
        case actionTypes.PHONE_SAVE:
            return { ...state, addPhone: { ...action.payload }, };
        default:
            return state;
    }
}