import React from 'react';
import PropTypes from 'prop-types';
import Title from '../../../component/title/index';
import './mySolution.less'

/* 定义参数类型 */
const propTypes = {
    title: PropTypes.string.isRequired,
    userImg: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    img: PropTypes.string.isRequired,
    leftText: PropTypes.string.isRequired,
    rightText: PropTypes.string.isRequired,
    bgColor: PropTypes.string.isRequired,
}

/* 轮播图片组件 */
function MySolution(props) {
    let { title, userImg, img, content, leftText, rightText, bgColor } = props;
    return (
        <div className="my-solution" style={{ backgroundColor: bgColor }}>
            <div className="container">
                <Title>{title}</Title>
                <div className="userinfo">
                    <div className="user-img"><img src={userImg} alt='' /></div>
                    <div className="user-content" dangerouslySetInnerHTML={{ __html: content }} ></div>
                </div>
                <div className="img"><img src={img} alt='' /></div>
                <div className="text">
                    <div className="left-text">{leftText}</div>
                    <div className="right-text">{rightText}</div>
                </div>

                {/* 
                {imgAlign === 'left' ? <div className="img"><img src={img} alt='' /></div> : null}
                <div className="text">
                    <div className="title">{title}</div>
                    <div className="sub-title">{subTitle}</div>
                    <div className="text-content" dangerouslySetInnerHTML={{ __html: content }} ></div>
                </div>
                {imgAlign === 'right' ? <div className="img"><img src={img} alt='' /></div> : null} */}


            </div>
        </div>
    );
}

MySolution.propTypes = propTypes;

export default MySolution;