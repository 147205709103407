

import request from '../../utils/request';

//菜小秘大前端
export function getCxmData() {
    return request({ url: 'app/selectAppversionForList', params: { marketType: '1', os: 'INTEGRATIONAPP' } });
}
//菜小秘蔬菜版
export function getPosData() {
    return request({ url: 'app/selectAppversionForList', params: { marketType: '1', os: 'android' } });
}
//获得手机收银机的数据
export function getMobilePosData() {
    return request({ url: 'app/selectAppversionForList', params: { marketType: '15', os: 'androidPhone' } });
}
//获得手机档主端的数据
export function getMobileStallData() {
    return request({ url: 'app/selectAppversionForList', params: { marketType: '1', os: 'stallPhone' } });
}