import 'babel-polyfill';//解决ie兼容性问题，主要是不支持es6语法
// These must be the first lines in src/index.js
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'raf/polyfill';//ie 9报错，主要是不支持Map和Set函数： Warning: React depends on requestAnimationFrame. Make sure that you load a polyfill in older browsers. https://fb.me/react-polyfills

import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { BrowserRouter as Router } from 'react-router-dom';

import { ConfigProvider } from 'antd';
// 由于 antd 组件的默认文案是英文，所以需要修改为中文
// import enUS from 'antd/es/locale/en_US';
import zhCN from 'antd/es/locale/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';
// import 'antd/dist/antd.css';

import Error from './error';

import App from './App';
import reducers from './reducers';
import './ie9classList';//解决ie9不支持classList属性的问题
import './axiosConfig';
import './index.css';
// import './caixm.less';

import * as serviceWorker from './serviceWorker';

// const basename = '/share';
let basename = '/#';

//开发环境
// console.log( window.__REDUX_DEVTOOLS_EXTENSION__)
// const store = createStore(reducers, compose(applyMiddleware(thunk), window.devToolsExtension ? window.devToolsExtension() : f => f));
const store = createStore(reducers, compose(applyMiddleware(thunk), window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f));
//正式环境，不让浏览器redux插件查看状态
// const store = createStore(reducers, compose(applyMiddleware(thunk), f => f));

// moment.locale('en');
moment.locale('zh-cn');

ReactDOM.render(<Provider store={store}>
    <Error>
        <ConfigProvider locale={zhCN}>
            <Router basename={basename}>
                <App></App>
            </Router>
        </ConfigProvider>
    </Error>
</Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();