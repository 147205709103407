import React, { Component } from 'react';
// import PropTypes from 'prop-types';
// import { connect } from 'react-redux';
import { Row, Col } from 'antd';
// import { withRouter, } from 'react-router-dom';
import imgPhoto from '../../common/images/client/photo.png';
// import imgPhoto1 from '../../common/images/client/photo1.png';
import imgPhoto1 from '../../common/images/client/photo1.jpg';
// import imgPhoto2 from '../../common/images/client/photo2.png';
import imgPhoto2 from '../../common/images/client/photo2.jpg';
// import imgPhoto3 from '../../common/images/client/photo3.png';
import imgPhoto3 from '../../common/images/client/photo3.jpg';
// import imgPhoto4 from '../../common/images/client/photo4.png';
import imgPhoto4 from '../../common/images/client/photo4.jpg';
import img1 from '../../common/images/client/img1.png';
import img11 from '../../common/images/client/img1-1.png';
import img12 from '../../common/images/client/img1-2.png';
import img13 from '../../common/images/client/img1-3.png';
import img14 from '../../common/images/client/img1-4.png';
import img2 from '../../common/images/client/img2.png';
import img21 from '../../common/images/client/img2-1.png';
import img22 from '../../common/images/client/img2-2.png';
import img23 from '../../common/images/client/img2-3.png';
import img24 from '../../common/images/client/img2-4.png';
import img3 from '../../common/images/client/img3.png';
import img31 from '../../common/images/client/img3-1.png';
import img32 from '../../common/images/client/img3-2.png';
import img33 from '../../common/images/client/img3-3.png';
import img34 from '../../common/images/client/img3-4.png';
import img4 from '../../common/images/client/img4.png';
import img41 from '../../common/images/client/img4-1.png';
import img42 from '../../common/images/client/img4-2.png';
import img43 from '../../common/images/client/img4-3.png';
import img44 from '../../common/images/client/img4-4.png';
// import imgScroll1 from '../../common/images/client/scroll1.png';
// import imgScroll2 from '../../common/images/client/scroll2.png';
// import imgScroll3 from '../../common/images/client/scroll3.png';

import Title from '../../component/title/index';
import Varieties from './index/varieties';
import MarqueePage2 from './index/marqueePage';
// import MarqueePage from '../../component/react-marquee/demo/demo';

import './style.less';

// @withRouter
class Client extends Component {

    render() {
        // const layout1 = { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 };
        const layout1 = { xs: 6, sm: 6, md: 6, lg: 6, xl: 6 };
        const layout3 = { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 };
        return <div className='client'>
            <div className='banner'>
                <Title>典型案例</Title>
                <div className="container">
                    <Row gutter={14} type="flex" justify="space-between" align="top">
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="banner-left" >
                            <img src={imgPhoto} alt="" />
                            <div className="banner-text">
                                <span className="banner-title">华商九通</span>
                                <span className="banner-content">武汉四季美头档</span>
                            </div>
                            <div className="banner-text-hover">
                                <span className="banner-title">华商九通</span>
                                <span className="banner-content">
                                    作为武汉四季美第一商行之一，菜小秘成功帮助商行打破传统手工记账， 实现快速下单收银，收银员表示，对新技术非常好奇，自从用了菜小秘再也不想回到手工记账，觉得非常方便！
                                </span>
                            </div>
                        </Col>
                        <Col {...layout1} className="banner-right">
                            <div>
                                <div className='newImgBox'>
                                    <img src={imgPhoto1} alt="" />
                                    <div className="banner-text">
                                        <span className="banner-title">全联盛商贸</span>
                                        <span className="banner-content">北京新发地“菜王”</span>
                                    </div>
                                    <div className="banner-text-hover">
                                        <span className="banner-title">全联盛商贸</span>
                                        <span className="banner-content">
                                            全联盛商贸档主表示，使用菜小秘后立竿见影大大帮助商行提升效率，我们有4个档口，一天几千张单子，至少10个小时人工，重要的是，准确度非常高，所以以后我也会毫不犹豫选择菜小秘。
                                    </span>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <div>
                                <div className='newImgBox'>
                                    <img src={imgPhoto3} alt="" />
                                    <div className="banner-text">
                                        <span className="banner-title">林氏菇业</span>
                                        <span className="banner-content">杭州林氏菇业</span>
                                    </div>
                                    <div className="banner-text-hover">
                                        <span className="banner-title">杭州林氏菇业</span>
                                        <span className="banner-content">
                                            {/* 作为湖南精品头档商行，档主表示对成本控制、协同管理、查账管账等需求是重中之重。正因如此，菜小秘根据需求定制化服务，专注满足商行需求，成功帮助商行解决赊欠、下单等问题，大大提高效率。 */}
                                            杭州林氏菇业档主表示对成本控制、协同管理、查账管账等需求是重中之重。正因如此，菜小秘根据需求定制化服务，专注满足商行需求，成功帮助商行解决赊欠、下单等问题，大大提高效率。
                                    </span>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col {...layout1} className="banner-right">
                            <div>
                                <div className='newImgBox'>
                                    <img src={imgPhoto2} alt="" />
                                    <div className="banner-text">
                                        <span className="banner-title">上海伟岳菇行</span>
                                        <span className="banner-content">上海伟岳菇行</span>
                                    </div>
                                    <div className="banner-text-hover">
                                        <span className="banner-title">上海伟岳菇行</span>
                                        <span className="banner-content">
                                            菜小秘可以随时随地进行经营管理，档主表示以前经常需要几个人每天对账，现在使用菜小秘档主端APP，数据报表非常清晰，实时查看数据报表，心里有底，对于进销计划也有底。
                                    </span>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <div>
                                <div className='newImgBox'>
                                    <img src={imgPhoto4} alt="" />
                                    <div className="banner-text">
                                        <span className="banner-title">老蔡商行</span>
                                        <span className="banner-content">武汉白沙洲</span>
                                    </div>
                                    <div className="banner-text-hover">
                                        <span className="banner-title">武汉白沙洲老蔡商行</span>
                                        <span className="banner-content">
                                            老蔡商行的蔡静，从小在农批市场长大，是名副其实跟着市场长大的“批二代”。她认为追赶“批一代”的捷径是新技术。通过菜小秘帮她解决赊欠管理问题，大大节省对账时间，每天多出3小时休息时间。
                                    </span>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className="content2">
                <Title>覆盖市场</Title>
                {/* <MarqueePage /> */}
                <MarqueePage2 />
            </div>

            <div className="content3">
                <Title>品类细分</Title>
                <div className="container">
                    <Row gutter={20} type="flex" justify="space-between" align="middle">
                        <Col {...layout3}>
                            <Varieties img={img1} img1={img11} img2={img12} img3={img13} img4={img14} title="生姜蒜米" title1="武汉江山商行" title2="武汉兰陵商行" title3="长沙老庄商行" title4="长沙天艳贸易" />
                        </Col>
                        <Col {...layout3}>
                            <Varieties img={img2} img1={img21} img2={img22} img3={img23} img4={img24} title="菌菇" title1="杭州林氏菇业" title2="上海伟岳菇行" title3="杭州盛泉菇业" title4="上海兴宁食用菌商行" />
                        </Col>
                        <Col {...layout3}>
                            <Varieties img={img3} img1={img31} img2={img32} img3={img33} img4={img34} title="时令蔬菜" title1="长沙宁乡堂客商行" title2="长沙汪记南北蔬菜" title3="北京全联盛商贸" title4="合肥绿鼎蔬菜" />
                        </Col>
                        <Col {...layout3}>
                            <Varieties img={img4} img1={img41} img2={img42} img3={img43} img4={img44} title="其他" title1="武汉百菜园" title2="南京旺旺顺商行" title3="武汉新绿源商行" title4="长沙南北辣椒商行" />
                        </Col>
                    </Row>
                </div>
            </div>
        </div>

    }

}

export default Client;