import React, { Component } from 'react'

import Marquee from '../../../component/react-marquee/index';

import imgScroll1 from '../../../common/images/client/scroll1.png';
import imgScroll2 from '../../../common/images/client/scroll2.png';
import imgScroll3 from '../../../common/images/client/scroll3.png';
import imgScroll4 from '../../../common/images/client/scroll4.png';
import imgScroll5 from '../../../common/images/client/scroll5.png';
// import imgScroll6 from '../../../common/images/client/scroll6.png';
import imgScroll6 from '../../../common/images/client/scroll6.jpg';
import imgScroll7 from '../../../common/images/client/scroll7.png';


import './marqueePage.less'

class MarqueePage extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      loopData: [
        { img: imgScroll1, title: '河南万邦国际', date: 'Nov 8, 2017' },
        { img: imgScroll2, title: '武汉四季美', date: 'Nov 9, 2017' },
        { img: imgScroll3, title: '武汉白沙洲', date: 'Oct 17, 2018' },
        { img: imgScroll4, title: '北京新发地', date: 'Oct 2, 2018' },
        { img: imgScroll5, title: '南京众彩市场', date: 'Sep 13, 2019' },
        { img: imgScroll6, title: '上海江桥市场', date: 'Mar 20, 2019' },
        { img: imgScroll7, title: '合肥百大周谷堆', date: 'Mar 20, 2019' },
      ],
    }
  }


  render() {
    let { loopData } = this.state;
    return (
      <div className="my-marquee-page">
        <Marquee loopData={loopData} getMarquee={this.getLandscapeMarquee} >
          {loopData.map((v, i) => {
            const { img, title, date } = v;
            return <div key={i} className="marquee-landscape-txt">
              <div className="img"><img src={img} alt='' /></div>
              <div className="title">{title}</div>
              <div className="date">{date}</div>
            </div>
          }
          )}
        </Marquee>
        {/* <div style={{ marginTop: 400, position: 'absolute' }}>
          <div className="botton" onClick={this.runlandscapeMarquee}>运动</div>
          <div className="botton" onClick={this.stoplandscapeMarquee}>暂停</div>
        </div> */}

      </div>
    )
  }

  // 横向
  getLandscapeMarquee = (params) => {
    this.landscapeMarqueeParams = params
  }

  stoplandscapeMarquee = () => {
    this.landscapeMarqueeParams.stopMarquee();
  }

  runlandscapeMarquee = () => {
    this.landscapeMarqueeParams.runMarquee();
  }


}

export default MarqueePage;