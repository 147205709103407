//合并所有reducer 并且返回
import { combineReducers } from 'redux';
import { download } from './state/reducer/download';
import { phone } from './state/reducer/phone';

export default combineReducers({
    download,
    phone,
})

