import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    carouselNavItems: PropTypes.array.isRequired,
    currentIndex: PropTypes.number.isRequired,
    selectImage: PropTypes.func.isRequired
}

/* 底部显示组件 */
function CarouselNav2(props) {
    let { carouselNavItems, currentIndex, selectImage, height } = props;
    const opacity = [0.7, 0.6, 0.5, 0.4];//透明色
    return (
        <ul className="carousel-nav2" style={{ height: height }}>
            {
                carouselNavItems.map(function (item, i) {
                    // if (i == currentIndex) {
                    return <li className={`carousel-nav-item ${i === currentIndex ? 'carousel-nav-item-active' : ''}`} key={i} onClick={() => selectImage(i)} style={{ opacity: opacity[i > 3 ? 3 : i] }}>{i + 1}</li>
                    // } else {
                    //     return <li className="carousel-nav-item" key={i} onClick={() => selectImage(i)}>{i+1}</li>
                    // }
                })
            }
        </ul>
    );
}

CarouselNav2.propTypes = propTypes;

export default CarouselNav2;